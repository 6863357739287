<template>
  <div class="he-ui-phase-container d-flex flex-column justify-content-start align-items-center" :class="{'phase-enabled': isPhaseEnabled}">
    <div class="icon d-flex align-items-center justify-content-center">
      <!-- @MOCK -->
      <img src="{{data.locationType.icon}}">
    </div>

    <span class="title">
      {{ data.locationType.name }}
    </span>

    <div class="h-100 d-flex flex-column justify-content-around">
      <!-- @TODO make a component -->
      <div class="data-pill d-flex flex-rox align-items-start">
        <div class="pill-icon d-flex justify-content-center align-items-center">
            <!-- @MOCK -->
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.25 16.5H3.75C2.92157 16.5 2.25 15.8284 2.25 15V4.5C2.25 3.67157 2.92157 3 3.75 3H5.25V1.5H6.75V3H11.25V1.5H12.75V3H14.25C15.0784 3 15.75 3.67157 15.75 4.5V15C15.75 15.8284 15.0784 16.5 14.25 16.5ZM3.75 7.5V15H14.25V7.5H3.75ZM3.75 4.5V6H14.25V4.5H3.75ZM12.75 13.5H11.25V12H12.75V13.5ZM9.75 13.5H8.25V12H9.75V13.5ZM6.75 13.5H5.25V12H6.75V13.5ZM12.75 10.5H11.25V9H12.75V10.5ZM9.75 10.5H8.25V9H9.75V10.5ZM6.75 10.5H5.25V9H6.75V10.5Z" fill="#368AFF"/>
            </svg>
        </div>
        <div class="d-flex flex-column align-items-start justify-content-center h-100">
          <span class="pill-title">Día</span>
          <span class="pill-data">{{ this.printOnlyDate(data.updateDate) }}</span>
        </div>
      </div>
      <!-- end component -->

      <!-- @TODO make a component -->
      <div class="data-pill d-flex flex-rox align-items-start">
        <div class="pill-icon d-flex justify-content-center align-items-center">
            <!-- @MOCK -->
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.4955 13.1403 13.1403 16.4955 9 16.5ZM9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15C12.3137 15 15 12.3137 15 9C14.9963 5.68783 12.3122 3.00372 9 3ZM12.75 9.75H8.25V5.25H9.75V8.25H12.75V9.75Z" fill="#368AFF"/>
            </svg>
        </div>
        <div class="d-flex flex-column align-items-start justify-content-center h-100">
          <span class="pill-title">Hora</span>
          <span class="pill-data">{{ this.printOnlyHour(data.updateDate) }}</span>
        </div>
      </div>
      <!-- end component -->

      <!-- @TODO make a component -->
      <div class="data-pill d-flex flex-rox align-items-start">
        <div class="pill-icon d-flex justify-content-center align-items-center">
            <!-- @MOCK -->
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.50065 16.625C8.50072 15.7721 7.57388 14.837 6.72982 13.8296C5.46315 12.3168 3.95899 10.0637 3.95899 7.91668C3.95786 5.67432 5.30818 3.65228 7.37983 2.79412C9.45148 1.93595 11.8361 2.41082 13.421 3.99713C14.4632 5.0347 15.047 6.44607 15.0423 7.91668C15.0423 10.0637 13.5382 12.3168 12.2715 13.8296C11.4274 14.837 10.5006 15.7721 9.50065 16.625ZM9.50065 3.95834C7.31561 3.96096 5.54494 5.73163 5.54232 7.91668C5.54232 8.83976 5.95953 10.4381 7.94503 12.8139C8.43439 13.3982 8.95362 13.9568 9.50065 14.4875C10.0477 13.9575 10.5672 13.3997 11.0571 12.8163C13.0418 10.4373 13.459 8.83897 13.459 7.91668C13.4564 5.73163 11.6857 3.96096 9.50065 3.95834ZM9.50065 10.2917C8.18898 10.2917 7.12565 9.22835 7.12565 7.91668C7.12565 6.605 8.18898 5.54168 9.50065 5.54168C10.8123 5.54168 11.8757 6.605 11.8757 7.91668C11.8757 8.54657 11.6254 9.15066 11.18 9.59606C10.7346 10.0415 10.1305 10.2917 9.50065 10.2917Z" fill="#368AFF"/>
            </svg>
        </div>
        <div class="d-flex flex-column align-items-start justify-content-center h-100">
          <span class="pill-title">Ubicación</span>
          <span class="pill-data">{{ data.location.name }}</span>
        </div>
      </div>
      <!-- end component -->

      <!-- @TODO make a component -->
      <div class="data-pill d-flex flex-rox align-items-start">
        <div class="pill-icon d-flex justify-content-center align-items-center">
            <!-- @MOCK -->
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.25 6C5.25 3.92893 6.92893 2.25 9 2.25C11.0711 2.25 12.75 3.92893 12.75 6C12.75 8.07107 11.0711 9.75 9 9.75C6.92893 9.75 5.25 8.07107 5.25 6ZM9 8.25C10.2426 8.25 11.25 7.24264 11.25 6C11.25 4.75736 10.2426 3.75 9 3.75C7.75736 3.75 6.75 4.75736 6.75 6C6.75 7.24264 7.75736 8.25 9 8.25Z" fill="#368AFF"/>
              <path d="M4.75736 12.2574C3.63214 13.3826 3 14.9087 3 16.5H4.5C4.5 15.3065 4.97411 14.1619 5.81802 13.318C6.66193 12.4741 7.80653 12 9 12C10.1935 12 11.3381 12.4741 12.182 13.318C13.0259 14.1619 13.5 15.3065 13.5 16.5H15C15 14.9087 14.3679 13.3826 13.2426 12.2574C12.1174 11.1321 10.5913 10.5 9 10.5C7.4087 10.5 5.88258 11.1321 4.75736 12.2574Z" fill="#368AFF"/>
            </svg>
        </div>
        <div class="d-flex flex-column align-items-start justify-content-center h-100">
          <span class="pill-title">Staff</span>
          <span class="pill-data">{{ data.staff.email }}</span>
        </div>
      </div>
      <!-- end component -->
    </div>
  </div>
</template>

<script>
import mixins from '@/composition/mixins'

export default {

  setup () {
    const { getOnlyDate, getOnlyHour } = mixins()

    return {
      getOnlyDate,
      getOnlyHour
    }
  },

  props: {
    data: {
      type: Object,
      default: null
    }
  },

  computed: {
    isPhaseEnabled () {
      // @MOCK At first only enabled phases will be printed, therefore all will have the class
      return true
    }
  },

  methods: {
    printOnlyDate (fullDate) {
      return this.getOnlyDate(fullDate)
    },
    printOnlyHour (fullDate) {
      return this.getOnlyHour(fullDate)
    }
  }

}
</script>

<style lang="scss" scoped>
  .he-ui-phase-container {
    height: 82vh;
    width: 276px;
    margin: 14px;
    border-radius: 14px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
    background: #F5F5F5;
    &.phase-enabled {
      background: #FFF;
    }
  }

  .icon {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    background: rgba(54, 138, 255, 0.08);
    margin: 46px;
  }

  .title {
    text-transform: uppercase;
    color: #368AFF;
    font-size: 23px;
    font-weight: 800;
  }

  .data-pill {
    border-radius: 14px;
    width: 223px;
    height: 68px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    .pill-icon {
      background: rgba(54, 138, 255, 0.08);
      height: 76%;
      border-radius: 8px;
      margin: 9px;
      padding-left: 6px;
      padding-right: 6px;
    }
    .pill-title {
      font-size: 14px;
      font-weight: 800;
      color: #000;
    }
    .pill-data {
      font-size: 14px;
      font-weight: 500;
      color: #000;
    }
  }
</style>
