import { USER_DATA } from '@/constants/constants'

export default () => {
  const getIdTenant = () => {
    return JSON.parse(localStorage.getItem(USER_DATA)).tenant.id
  }

  const sendDataToParentClient = (HHID, isNew = false) => {
    const heuristikMessage = {
      HHID: HHID,
      isNew: isNew
    }
    window.parent.postMessage(heuristikMessage, '*')
  }

  // ----- //
  // DATES //
  // ----- //

  const getTomorrowFromNowOnISO = () => {
    const now = new Date()
    return new Date(now.setDate(now.getDate() + 1)).toISOString()
  }

  const getYesterdayFromNowOnISO = () => {
    const now = new Date()
    return new Date(now.setDate(now.getDate() - 1)).toISOString()
  }

  /**
   * A function for extract the hour from a full date string with a particular format from backend
   * @param {String} fullDate - String of date with this format: Mon d, YYYY, h:mm:ss AM
   * @returns A substring from the second comma to the end, with the hour: hh:mm:ss AM
   */
  const getOnlyHour = (fullDate) => {
    return fullDate.split(',')[2]
  }

  /**
   * A function for extract the date without hour from a full date string with a particular format from backend
   * @param {String} fullDate - String of a date with this format: Mon dd, YYYY, h:mm:ss AM
   * @returns A substring from the beginning to the second comma, with the date: Mon d, YYYY
   */
  const getOnlyDate = (fullDate) => {
    const fullDateSplitted = fullDate.split(',')
    return `${fullDateSplitted[0]}${fullDateSplitted[1]}`
  }

  return {
    getIdTenant,
    getOnlyDate,
    getOnlyHour,
    getTomorrowFromNowOnISO,
    getYesterdayFromNowOnISO,
    sendDataToParentClient
  }
}
