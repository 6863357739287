<template>
  <div class="he-ui-location-overview d-flex justify-content-start">
    <search-recents-list />

    <phase
      v-for="phase in getPatientIdentifications"
      :key="phase.id"
      :data="phase"
      :showTimeCircle="false"
      :showButton="false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Phase from '@/components/location/Phase'
import SearchRecentsList from '@/components/location/SearchRecentsList.vue'

export default {
  components: {
    Phase,
    SearchRecentsList
    // PhaseReport
  },

  computed: {
    ...mapGetters([
      'getPatientIdentifications'
    ])
  }
}
</script>

<style lang="scss" scoped>
.he-ui-location-overview {
  width: 100%;
}
</style>
